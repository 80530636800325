<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col text-center" style="background-color: #eedc00">
        <img
          alt="Poste Italiane"
          style="padding-top: 17px; padding-bottom: 17px"
          src="https://www.poste.it/img/1473803290446/logo-poste-italiane-small.png"
          srcset="
            https://www.poste.it/img/1473803290446/2X/logo-poste-italiane-small.png 2x
          "
        />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6">
        <soft-phone
          :ccpUrl="ccpUrl"
          :loginUrl="loginUrl"
          style="height: 600px"
        />
      </div>
      <div class="col-6 my-auto">
        <v-card style="border: 1px solid #9a9a9a">
          <v-card-title class="card-title">Customer Credentials</v-card-title>
          <v-data-table
            id="keyValueHeaderTags"
            :headers="keyValueHeaderTags"
            :items="items"
            :items-per-page="-1"
            :hide-default-footer="true"
          ></v-data-table>
        </v-card>
        <div class="text-center mt-4" v-show="!outbound">
          <v-btn
            color="#eedc00"
            light
            @click="apriPortale(false)"
            rounded
            :disabled="!contactId"
          >
            Portale
          </v-btn>
        </div>
        <div class="text-center mt-4" v-show="outbound">
          <v-btn
            color="#eedc00"
            light
            @click="apriPortale(k)"
            rounded
            :disabled="arrayBtn.length == 0 || !arrayBtn.includes(k)"
            v-for="k in Object.keys(redirects)"
            :key="k"
          >
            <span v-if="k == 'DEFAULT' || k == 'LOMBARDIA'">
              Portale vaccini {{ k }}
            </span>
            <span v-else-if="k == 'DECODER'"> Portale {{ k }} </span> </v-btn
          ><!-- :disabled="!contactId" --><!-- regionValue != k -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftPhone from "./SoftPhone";
const querystring = require("querystring");

export default {
  name: "Home",
  components: {
    SoftPhone,
  },
  mounted() {
    window.onbeforeunload = function () {
      let agent = new window.connect.Agent();
      if (agent.getState().type != "offline") {
        var offlineIndex = agent
          .getAgentStates()
          .map((e) => e.type)
          .indexOf("offline");
        var state = agent.getAgentStates()[offlineIndex];
        agent.setState(state);
        //return "Logout"
      }
    };

    var bus = window.connect.core.getEventBus();

    bus.subscribe(window.connect.ContactEvents.CONNECTED, (e) => {
      console.log(window.connect.ContactEvents.CONNECTED);
      console.log(e);
      let queueNumb = e.toSnapshot().contactData.queue.name;
      console.log(queueNumb);
      this.$set(this.dict, "ID Contatto", e.contactId);
      let agent = new window.connect.Agent();
      console.log(window.connect);
      console.log(agent);
      let username = agent.getConfiguration().username.split("@")[0];
      this.$set(this.dict, "Agente", username);
      let contact = agent.getContacts()[0];
      console.log(contact.getAttributes());
      /* console.log(agent.getContacts()[0].getConnections()[1]); */
      let customerNumber = contact
        .getConnections()[1]
        .getAddress()
        .phoneNumber.substr(3);
      this.$set(this.dict, "Numero Chiamante", customerNumber);

      this.$set(this.dict, "Numero Coda", queueNumb);
      let attributes = contact.getAttributes();

      if (attributes.regione) {
        this.regionValue = attributes.regione.value;
        this.arrayBtn.push(this.regionValue)
        console.log(attributes.regione.value);
        if (this.regionValue != "DECODER") {
          let dictAttributes = Object.assign(
            {},
            ...Object.entries(attributes).map(([, { name, value }]) => ({
              [name]: value,
            }))
          );
          this.valore = dictAttributes.regione;
          this.$set(this, "contactAttributes", dictAttributes);
        }
      } else if(attributes.servizio){
        let servizio = attributes.servizio.value
        if(servizio == "VACCINI"){
        this.arrayBtn.push("LOMBARDIA")
        this.arrayBtn.push("DEFAULT")

          console.log('servizio vaccini')
        }else if(servizio == "DECODER"){
          console.log('servizio decoder')
        this.arrayBtn.push("DECODER")

        }
      }
    });
    bus.subscribe(window.connect.ContactEvents.ENDED, () => {
      this.$set(this, "dict", {});
      this.$set(this, "contactAttributes", {});
      this.regionValue = "empty";
      this.arrayBtn = [];
    });
  },
  computed: {
    outbound() {
      return !this.redirectUrl;
    },
    redirectUrl() {
      return this.contactAttributes["_REDIRECT_URL"];
    },
    items() {
      return [
        ...Object.entries(this.dict).map(([tagKey, tagValue]) => ({
          tagKey,
          tagValue,
        })),
        ...Object.entries(this.contactAttributes).map(([tagKey, tagValue]) => ({
          tagKey,
          tagValue,
        })),
      ];
    },
  },
  methods: {
    apriPortale(regione) {
      console.log(regione);
      window.open(
        `${
          regione ? this.redirects[regione] : this.redirectUrl
        }?${querystring.stringify({
          user: this.dict["Agente"],
          msisdn: this.dict["Numero Chiamante"],
          /*  coda: this.dict["Numero Coda"], */
        })}`
      );
    },
  },
  data() {
    return {
      regionValue: "empty",
      arrayBtn: [],
      keyValueHeaderTags: [
        {
          text: "Key",
          value: "tagKey",
          width: 400,
          sortable: false,
        },
        {
          text: "Value",
          value: "tagValue",
          width: 400,
          sortable: false,
        },
      ],
      dict: {},
      contactAttributes: {},
      redirects: {
        DEFAULT: "https://vaccinicovid.contactcenter.poste.it/cit/index.html#/",
        LOMBARDIA:
          "https://vaccinicovidlombardia.contactcenter.poste.it/cit/index.html#/",
        DECODER: "https://callcenter.decoder.poste.it/",
      },
      // ------## stormconnectdemo ##------
      // ccpUrl: "https://stormconnectdemo.awsapps.com/connect/ccp-v2/",
      // loginUrl: undefined,
      // ------## testccvaccini ##------
      /* ccpUrl: "https://testccvaccini.my.connect.aws/connect/ccp-v2/", */
      // loginUrl: undefined,
      // ------## testccvaccinisso ##------
      // ccpUrl: "https://testccvaccinisso.my.connect.aws/connect/ccp-v2",
      // loginUrl: "https://myapps.microsoft.com/signin/AWS%20Connect%20Vaccini%20SSO%20Agent%20Dev/662bf4b1-7fa6-49f4-a464-e64dc8551aaf?tenantId=761de76f-3d5c-4174-917c-5ad4d06360cb"
      // ------## posteccvaccinisso01 ##------

       ccpUrl: "https://posteccvaccinisso01.my.connect.aws/connect/ccp-v2/",
      loginUrl:
        "https://myapps.microsoft.com/signin/AWS%20Connect%20Vaccini%20SSO%20Agent%20Prod/ed3e1616-b0ba-4a23-b233-966f30432bca?tenantId=761de76f-3d5c-4174-917c-5ad4d06360cb", 
    };
  },
};
</script>

<style>
:root {
  --primary: #eedc00;
}

#keyValueHeaderTags th {
  background-color: #eeeeee;
}
#keyValueHeaderTags th {
  display: none;
}
#keyValueHeaderTags td {
  padding-left: 200px;
  background-color: #f5f5f5;
}

.card-title {
  background-color: #4a4a4a;
  color: white;
  font-size: 14px !important;
  padding: 5px !important;
  border: 1px solid #f5f5f5;
  margin: 0 !important;
}
</style>