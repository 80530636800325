<template>
  <div></div>
</template>

<script>
export default {
  props: {
    ccpUrl: String,
    region: {
      type: String,
      default: "eu-central-1",
    },
    loginUrl: {
      type: String,
      default: undefined,
    },
  },
  mounted() {
    window.connect.core.initCCP(this.$el, {
      ccpUrl: this.ccpUrl,
      loginUrl: this.loginUrl,
      region: this.region,
      loginPopup: true,
      loginPopupAutoClose: true,
      softphone: {
        allowFramedSoftphone: true,
      },
    });
  },
  updated(){
console.log(window.connect.Agent.prototype.getState().name)
  },
  beforeDestroy() {
    window.connect.core.terminate()
  }
};
</script>